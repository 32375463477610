import React, { memo } from "react";
import { Box, Container, Typography } from "@mui/material";
import styles from "./style.module.scss";
import Link from "components/Link";
import { ArrowForwardIos } from "@mui/icons-material";
import theme from "theme";

const PopularResultsListElement = ({ text, link }) => {
  return (
    <li>
      <Link href={link}>
        <Typography component="span">{text}</Typography>
      </Link>
    </li>
  );
};

const objElements = [
  { label: "Warszawa Mokotów", value: "city_id=2&district_id=4" },
  { label: "Warszawa Praga", value: "city_id=2&district_id=51" },
  { label: "Wrocław", value: "city_id=7" },
  { label: "Poznań", value: "city_id=16" },
  { label: "Katowice", value: "city_id=13" },
  { label: "Kraków", value: "city_id=5" },
];

const PopularResults = ({ filtersOptions }) => {
  const filterCities = () => {
    let citiesTempArr = [];
    if (!filtersOptions?.locations) return citiesTempArr;
    Object.values(filtersOptions.locations)?.map(({ cities }) => {
      Object.values(cities)?.map(({ id, name, quarters }) => {
        if (Object.keys(quarters).length > 0) {
          citiesTempArr.push({ value: `city_id=${id}`, label: name });
          Object.values(quarters)?.map(
            ({ id: searchQuarter, name: nameQuarter }) => {
              citiesTempArr.push({
                value: `city_id=${id}&district_id=${searchQuarter}`,
                label: `${name}, ${nameQuarter}`,
              });
            }
          );
        } else {
          citiesTempArr.push({ value: `city_id=${id}`, label: name });
        }
      });
    });

    const sortArr = citiesTempArr
      .sort(() => Math.random() - Math.random())
      .slice(0, 6)
      .sort((a, b) => a.label.localeCompare(b.label));

    return sortArr.reverse();
  };

  const getLink = (name) => {
    const find = filtersOptions?.realestate_types?.find(
      (el) => el?.name === name
    );

    if (find) {
      return find?.id;
    }
    return "1";
  };

  return (
    <Box
      mt={4}
      className={styles.containerPopular}
      display="flex"
      flexDirection="column"
    >
      <nav>
        <Box
          border={1}
          borderColor={"#e8e8e8"}
          borderRadius={1}
          flexGrow={1}
          px={4}
          py={4}
        >
          <Typography
            variant="h3"
            fontSize="1.25rem !important"
            style={{
              marginTop: "10px",
            }}
          >
            Mieszkania
          </Typography>
          <ul>
            {objElements?.map(({ value, label }) => (
              <PopularResultsListElement
                key={value + "_apartment_" + label}
                link={`/investments?market=primary&${value}&realestate_type_id=${getLink(
                  "Flat"
                )}`}
                text={`Mieszkanie ${label}`}
              />
            ))}
          </ul>
          <Link
            href={`/investments?market=primary&realestate_type_id=${getLink(
              "Flat"
            )}`}
          >
            <Box
              display="flex"
              alignItems="center"
              sx={{
                marginTop: "10px",
              }}
            >
              <Typography
                component="span"
                color="secondary"
                fontWeight={800}
                pr={2}
                sx={{
                  [theme.breakpoints.down("md")]: {
                    marginTop: "0 !important",
                  },
                }}
              >
                Pokaż wszystkie{" "}
              </Typography>
              <ArrowForwardIos
                color="secondary"
                sx={{
                  fontSize: "1em",
                }}
              />
            </Box>
          </Link>
        </Box>
        <Box
          border={1}
          borderColor={"#e8e8e8"}
          borderRadius={1}
          flexGrow={1}
          mx={2}
          p={4}
        >
          <Typography
            variant="h3"
            fontSize="1.25rem !important"
            style={{
              marginTop: "10px",
            }}
          >
            Domy
          </Typography>
          <ul>
            {objElements?.map(({ value, label }) => (
              <PopularResultsListElement
                key={value + "_house_" + label}
                link={`/investments?market=primary&${value}&realestate_type_id=${getLink(
                  "House"
                )}`}
                text={`Dom ${label}`}
              />
            ))}
          </ul>
          <Link
            href={`/investments?market=primary&realestate_type_id=${getLink(
              "House"
            )}`}
          >
            <Box display="flex" alignItems="center" sx={{ marginTop: "10px" }}>
              <Typography
                component="span"
                color="secondary"
                fontWeight={800}
                pr={2}
                sx={{
                  [theme.breakpoints.down("md")]: {
                    marginTop: "0 !important",
                  },
                }}
              >
                Pokaż wszystkie{" "}
              </Typography>
              <ArrowForwardIos
                color="secondary"
                sx={{
                  fontSize: "1em",
                }}
              />
            </Box>
          </Link>
        </Box>
        <Box
          border={1}
          borderColor={"#e8e8e8"}
          borderRadius={1}
          flexGrow={1}
          ml={2}
          p={4}
        >
          <Typography
            variant="h3"
            fontSize="1.25rem !important"
            style={{ marginTop: "10px" }}
          >
            Lokale
          </Typography>
          <ul>
            {objElements?.map(({ value, label }) => (
              <PopularResultsListElement
                key={value + "_office_" + label}
                link={`/investments?market=primary&${value}&realestate_type_id=${getLink(
                  "Local"
                )}`}
                text={`Lokal ${label}`}
              />
            ))}
          </ul>
          <Link
            href={`/investments?market=primary&realestate_type_id=${getLink(
              "Local"
            )}`}
          >
            <Box display="flex" alignItems="center" sx={{ marginTop: "10px" }}>
              <Typography
                component="span"
                color="secondary"
                fontWeight={800}
                pr={2}
                sx={{
                  [theme.breakpoints.down("md")]: {
                    marginTop: "0 !important",
                  },
                }}
              >
                Pokaż wszystkie{" "}
              </Typography>
              <ArrowForwardIos
                color="secondary"
                sx={{
                  fontSize: "1em",
                }}
              />
            </Box>
          </Link>
        </Box>
      </nav>
    </Box>
  );
};

export default PopularResults;

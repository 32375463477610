import React, { memo, useEffect, useState } from "react";

import SearchBox from "../../components/SearchBox";
import { Box, CircularProgress, Container } from "@mui/material";

import ActualOffers from "components/ActualOffers";
import HowWeWork from "components/HowWeWork";
import Discount from "components/Discount";
import RealestateFromExpander from "components/RealestateFromExpander";
import PopularResults from "components/PopularResults";
import { CloudSVG } from "assets/svg";
import theme from "theme";
import HomeContentBox from "components/HomeContentBox";
import BannerServices from "services/BannerServices";
import RealestatesServices from "services/RealestatesServices";

import { getSecondaryMarketMarker } from "utils/functions";
import InvestmentsServices from "services/InvestmentsServices";
import { useStoreActions } from "easy-peasy";

import Image from "next/image";
import lupaIcon from "assets/icons/lupa.png";
import chatIcon from "assets/icons/chat.png";
import domIcon from "assets/icons/dom.png";
import medalIcon from "assets/icons/medal.png";
import useEnableNavigation from "hooks/useEnableNavigation";

const HomePage = memo(() => {
  const [secondaryMarket, setSecondaryMarket] = useState([]);
  const [banners, setBanners] = useState([]);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [lastRealestates, setLastRealestates] = useState([]);

  const resetMarketTypeStore = useStoreActions(
    (state) => state.appInfo.resetMarket
  );

  const { dictionaryStore } = useEnableNavigation();

  useEffect(async () => {
    setLoading(false);
    resetMarketTypeStore();

    await BannerServices.getBanners().then((res) => {
      if (res?.status === 200) {
        const elements = [];
        res?.data?.data?.forEach((el) => {
          if (el?.places) {
            const find = el?.places.split(",").find((el) => el === "home");
            if (find) elements.push(el);
          }
        });
        setBanners(elements.slice(0, 2));
      }
    });

    await loadData();
    setLoading(true);
  }, []);

  const loadData = async () => {
    const dataInvestmentRecomended = [];
    await InvestmentsServices.getInvesments(5, 1, {
      order: "recommended_by_an_expert,desc",
    }).then((res) => {
      function randomOffers() {
        return res?.data?.data;
      }
      if (res?.status === 200) {
        dataInvestmentRecomended = randomOffers();
      }
    });

    setData(dataInvestmentRecomended);

    const dataLastRealestates = [];

    await RealestatesServices.getRealestates(4, {
      order: "id,desc",
      offer_kind_id: "1",
    }).then((res) => {
      if (res?.status === 200 && res?.data?.data) {
        dataLastRealestates = res?.data?.data;
      }
    });

    setLastRealestates(dataLastRealestates);

    await RealestatesServices.getRealestatesInvestment(
      process.env.SECONDARY_MARKET_UUID,
      4,
      1,
      { order: "id,desc" }
    ).then((res) => {
      if (res?.status === 200) {
        setSecondaryMarket(res?.data?.data);
      }
    });
  };

  return (
    <Box mb={10}>
      <Container style={{ maxWidth: "1730px", padding: 0 }}>
        <SearchBox filtersOptions={dictionaryStore} />
      </Container>
      <Container style={{ maxWidth: theme.breakpoints.values.lg }}>
        {data?.length !== 0 && (
          <HomeContentBox
            icon={
              <Image
                unoptimized
                alt={`chat icon`}
                src={chatIcon}
                width={40}
                height={36}
              />
            }
            title="Nasi eksperci polecają"
          >
            <ActualOffers
              investment
              actualOffers={data?.map((el) => {
                return {
                  ...el,
                  marker: {
                    first_line: "Polecane",
                    second_line: "przez experta",
                    icon: medalIcon,
                    iconWidth: 28,
                    iconHeight: 28,
                  },
                };
              })}
            />
          </HomeContentBox>
        )}
        <HomeContentBox
          icon={
            <Image alt={`chat icon`} src={chatIcon} width={40} height={36} />
          }
          title="Jak działamy?"
        >
          <HowWeWork />
        </HomeContentBox>
      </Container>
      <>
        <>
          {lastRealestates?.length !== 0 && (
            <Box bgcolor="grey.100" paddingTop={4} paddingBottom={12}>
              <Container style={{ maxWidth: theme.breakpoints.values.lg }}>
                <HomeContentBox
                  icon={
                    <Image
                      alt={`dom icon`}
                      src={domIcon}
                      width={43.2}
                      height={42.3}
                    />
                  }
                  title="Ostatnie mieszkania w inwestycjach deweloperów"
                >
                  <ActualOffers
                    actualOffers={lastRealestates}
                    backgroundColor="white"
                    lastOfferPrimaryMarket
                  />
                </HomeContentBox>
              </Container>
            </Box>
          )}
          <Container style={{ maxWidth: theme.breakpoints.values.lg }}>
            {!loading ? (
              <Box
                height={350}
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <CircularProgress />
              </Box>
            ) : (
              banners?.length > 0 && <Discount banners={banners} />
            )}
          </Container>{" "}
          {secondaryMarket?.length !== 0 && (
            <Box bgcolor="grey.100" paddingTop={4} paddingBottom={12}>
              <Container style={{ maxWidth: theme.breakpoints.values.lg }}>
                <HomeContentBox
                  icon={
                    <Image
                      alt={`dom icon`}
                      src={domIcon}
                      width={43.2}
                      height={42.3}
                    />
                  }
                  title="Ostatnio dodane na rynku wtórnym"
                >
                  {" "}
                  {!loading ? (
                    <Box
                      height={350}
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <CircularProgress />
                    </Box>
                  ) : (
                    <ActualOffers
                      actualOffers={secondaryMarket}
                      backgroundColor="white"
                      lastOfferSecondaryMarket
                    />
                  )}
                </HomeContentBox>
              </Container>
            </Box>
          )}
          <Container style={{ maxWidth: theme.breakpoints.values.lg }}>
            <RealestateFromExpander />
            <HomeContentBox
              icon={
                <Image
                  alt={`lupa icon`}
                  src={lupaIcon}
                  width={32}
                  height={40}
                />
              }
              title="Popularne wyszukania"
            >
              <PopularResults filtersOptions={dictionaryStore} />
            </HomeContentBox>
          </Container>
        </>
      </>
    </Box>
  );
});

HomePage.displayName = "HomePage";

export default HomePage;

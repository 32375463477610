import React, { memo, useEffect, useMemo, useState } from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import theme from "theme";
import style from "./style.module.scss";
import Select, { components } from "react-select";
import Button from "components/Button";
import { useForm, Controller } from "react-hook-form";
import { useRouter } from "next/router";
import { Hidden } from "@mui/material";
import { KeySVG } from "assets/svg";
import { Search } from "@mui/icons-material";
import Image from "next/image";
import ArrowRightImage from "../../../public/img/yellow-arrow2.png";
import ArrowLeftImage from "../../../public/img/red-arrow.png";

import pointIcon from "assets/icons/point.png";
import useEnableNavigation from "hooks/useEnableNavigation";
import { useStoreState } from "easy-peasy";

const SearchBox = memo(({ filtersOptions }) => {
  const router = useRouter();
  const { getEnableNavigation, getLink } = useEnableNavigation();
  const [typeOptions, setTypeOptions] = useState([]);
  const dictionaryStore = useStoreState((state) => state?.dictionary?.payload);

  const Control = ({ children, ...props }) => (
    <components.Control {...props}>
      <Box display="flex" alignItems="center" pl={2}>
        <Image
          unoptimized
          alt={`point icon`}
          src={pointIcon}
          width={17}
          height={26}
        />
      </Box>
      {children}
    </components.Control>
  );

  const { control, handleSubmit, watch, setValue, resetField } = useForm({
    defaultValues: {
      voivodeshipList: null,
      typeMarket: { value: "primary", label: "Rynek pierwotny" },
      type: null,
    },
  });
  const watchForm = watch();

  const setFilterVoivodeship = useMemo(() => {
    let citiesTempArr = [];
    if (!filtersOptions?.locations) return citiesTempArr;
    const typeMarket =
      watch()?.typeMarket?.value === "primary"
        ? "primary_marker"
        : "secondary_market";
    for (const [keyLocation, valueLocation] of Object.entries(
      filtersOptions?.locations
    )) {
      if (valueLocation?.name !== "") {
        if (valueLocation[typeMarket]) {
          citiesTempArr.push({
            value: `voivodeship_id[]=${valueLocation?.id}`,
            label: `${valueLocation?.name}`,
          });
        }
      }
    }

    const sortArr = citiesTempArr.sort((a, b) =>
      a.label.localeCompare(b.label)
    );

    return sortArr;
  }, [watch()?.typeMarket?.value, filtersOptions?.locations]);

  useEffect(() => {
    resetField("type");
    if (watch()?.typeMarket?.value === "primary") {
      const newElement = [];
      if (getEnableNavigation("Flat") === 1)
        newElement.push({ value: getLink("Flat"), label: "Mieszkanie" });
      if (getEnableNavigation("House") === 1)
        newElement.push({ value: getLink("House"), label: "Dom" });
      if (getEnableNavigation("Local") === 1)
        newElement.push({ value: getLink("Local"), label: "Lokal usługowy" });
      setTypeOptions(newElement);
    } else {
      const newElement = [];
      if (getEnableNavigation("Flat", "secondary_market") === 1)
        newElement.push({ value: getLink("Flat"), label: "Mieszkanie" });
      if (getEnableNavigation("House", "secondary_market") === 1)
        newElement.push({ value: getLink("House"), label: "Dom" });
      if (getEnableNavigation("Local", "secondary_market") === 1)
        newElement.push({ value: getLink("Local"), label: "Lokal usługowy" });
      if (getEnableNavigation("Terrain", "secondary_market") === 1)
        newElement.push({ value: getLink("Terrain"), label: "Działka" });
      if (
        getEnableNavigation("Garage", "secondary_market") === 1 ||
        getEnableNavigation("Hall", "secondary_market") === 1
      )
        newElement.push({ value: getLink("other"), label: "Inne" });

      setTypeOptions(newElement);
    }
  }, [watch()?.typeMarket?.value, dictionaryStore?.realestate_types]);

  const enableButton = useMemo(() => {
    // /  return true;
    if (watchForm.voivodeshipList) {
      return true;
    } else if (watchForm.type) {
      return true;
    } else if (watchForm.typeMarket) {
      return true;
    } else false;
  }, [watchForm]);

  const onSubmit = (data) => {
    const pathname = "/investments?market=";
    if (!enableButton) return;
    const query = {};

    if (watchForm.typeMarket) {
      pathname = pathname + `${data.typeMarket.value}`;
    } else {
      pathname = pathname + "primary";
    }
    if (watchForm.voivodeshipList) {
      pathname = pathname + `&${data.voivodeshipList.value}`;
    }
    if (watchForm.type) {
      pathname = pathname + `&realestate_type_id[]=${data.type.value}`;
    }

    router.push(pathname);
  };
  return (
    <Grid>
      <Box
        position="relative"
        bgcolor={theme?.palette.grey[200]}
        flex={1}
        sx={{
          [theme.breakpoints.up("md")]: {
            padding: "150px ",
            height: "630px",
          },
          [theme.breakpoints.down("md")]: {
            padding: 0,
          },
        }}
      >
        <Box sx={{ zIndex: -2 }}>
          <Image
            alt="images background"
            layout="fill"
            objectFit="cover"
            priority
            unoptimized
            src={process.env.HOMEPAGE_IMAGE}
          />
          <Hidden mdDown>
            <Box className={[style.arrowLeft, style.arrow]}>
              <Image
                alt="arrowRight"
                layout="fill"
                objectFit="cover"
                src={ArrowRightImage}
              />
            </Box>
            <Box className={[style.arrowRight, style.arrow]}>
              <Image
                alt="arrowLeft"
                layout="fill"
                objectFit="cover"
                src={ArrowLeftImage}
              />
            </Box>
          </Hidden>
        </Box>

        <Box
          mb={18}
          position="relative"
          className={style.searchContainer}
          borderRadius={2}
          sx={{
            [theme.breakpoints.up("md")]: {
              padding: "60px 100px",
            },
            [theme.breakpoints.down("md")]: {
              borderRadius: 0,
              padding: "60px 20px",
            },
          }}
        >
          <Box textAlign="center" color="white" className={style.searchTitle}>
            <Typography
              variant="h1"
              style={{ color: "white" }}
              sx={{
                [theme.breakpoints.up("md")]: {
                  fontSize: "3rem",
                },
                [theme.breakpoints.down("md")]: {
                  fontSize: "2rem",
                  textAlign: "left",
                },
              }}
            >
              WYSZUKIWARKA NIERUCHOMOŚCI
            </Typography>
            <Typography
              variant="h2"
              color="white"
              fontWeight={400}
              pt={2}
              pb={4}
              sx={{
                fontSize: "1.6rem !important",
                lineHeight: "1.4 !important",
                [theme.breakpoints.down("md")]: {
                  fontSize: "1.25rem",
                  textAlign: "left",
                  fontWeight: "normal",
                  paddingTop: 3,
                  paddingBottom: 4,
                },
              }}
            >
              Sprawdź bezpłatnie ceny 15 tys. mieszkań
            </Typography>
          </Box>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box
              sx={{
                [theme.breakpoints.down("md")]: {
                  paddingLeft: 0,
                  paddingRight: 0,
                },
              }}
              mt={4}
              px={2}
              maxWidth={650}
              display="flex"
              alignItems="center"
              justifyContent="center"
              margin="auto"
              height={54}
              className={style.inputContainer}
            >
              <Box width="100%" flex={1} height="100%">
                <Controller
                  control={control}
                  name="voivodeshipList"
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <Select
                      id="VoivodeshipSelectSearchBox"
                      instanceId="VoivodeshipSelectSearchBox"
                      options={setFilterVoivodeship}
                      value={value}
                      components={{ Control }}
                      onChange={onChange}
                      placeholder="Województwo"
                      noOptionsMessage={() => "Brak opcji do wyboru"}
                      styles={{
                        control: (styles) => ({
                          ...styles,
                          borderRadius: 0,
                          height: "100%",
                          fontSize: 16,
                        }),
                        container: (styles) => ({ ...styles, height: "100%" }),
                        placeholder: (styles) => ({
                          ...styles,
                          color: theme.palette.primary.main,
                        }),

                        indicatorsContainer: (styles) => ({
                          ...styles,
                          color: "red !important",
                        }),
                        indicatorSeparator: (styles) => ({
                          ...styles,
                          display: "none",
                        }),
                      }}
                    />
                  )}
                />
              </Box>
            </Box>
            <Box
              sx={{
                [theme.breakpoints.down("md")]: {
                  paddingLeft: 0,
                  paddingRight: 0,
                },
              }}
              px={2}
              maxWidth={650}
              display="flex"
              alignItems="center"
              justifyContent="center"
              margin="auto"
              marginTop={4}
              height={54}
              className={style.inputContainer}
            >
              <Box width="100%" flex={1} height="100%">
                <Controller
                  control={control}
                  name="type"
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <Select
                      id="TypeSelectSearchBox"
                      instanceId="TypeSelectSearchBox"
                      options={typeOptions}
                      value={value}
                      onChange={onChange}
                      placeholder="Typ nieruchomości"
                      styles={{
                        control: (styles) => ({
                          ...styles,
                          borderRadius: 0,
                          height: "100%",
                          fontSize: 16,
                        }),
                        container: (styles) => ({
                          ...styles,
                          height: "100%",
                        }),
                        placeholder: (styles) => ({
                          ...styles,
                          color: theme.palette.primary.main,
                        }),
                        valueContainer: (styles) => ({
                          ...styles,
                          paddingLeft: "20px",
                        }),
                        indicatorsContainer: (styles) => ({
                          ...styles,
                          color: "red !important",
                        }),
                        indicatorSeparator: (styles) => ({
                          ...styles,
                          display: "none",
                        }),
                      }}
                    />
                  )}
                />
              </Box>
              <Box
                sx={{
                  [theme.breakpoints.up("md")]: {
                    marginLeft: 6,
                  },
                  [theme.breakpoints.down("md")]: {
                    marginTop: 4,
                  },
                }}
                width="100%"
                flex={1}
                height="100%"
              >
                <Controller
                  control={control}
                  name="typeMarket"
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <Select
                      id="TypeMarketSelectSearchBox"
                      instanceId="TypeMarketSelectSearchBox"
                      options={[
                        { value: "primary", label: "Rynek pierwotny" },
                        { value: "secondary", label: "Rynek wtórny" },
                      ]}
                      value={value}
                      onChange={(e) => {
                        setValue("voivodeshipList", null);
                        onChange(e);
                      }}
                      placeholder="Rodzaj rynku"
                      styles={{
                        control: (styles) => ({
                          ...styles,
                          borderRadius: 0,
                          height: "100%",
                          fontSize: 16,
                        }),
                        container: (styles) => ({ ...styles, height: "100%" }),
                        placeholder: (styles) => ({
                          ...styles,
                          color: theme.palette.primary.main,
                        }),
                        valueContainer: (styles) => ({
                          ...styles,
                          paddingLeft: "20px",
                        }),
                        indicatorsContainer: (styles) => ({
                          ...styles,
                          color: "red !important",
                        }),
                        indicatorSeparator: (styles) => ({
                          ...styles,
                          display: "none",
                        }),
                      }}
                    />
                  )}
                />
              </Box>
              <Box
                marginLeft={6}
                height="100%"
                className={style.buttonContainer}
              >
                <Button
                  aria-label="szukaj"
                  color={!enableButton ? "disabled" : "secondary"}
                  type="submit"
                >
                  <Search sx={{ fontSize: 30 }} />
                </Button>
              </Box>
            </Box>
          </form>
        </Box>
      </Box>
    </Grid>
  );
});

export default SearchBox;

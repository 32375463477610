import { Box, Hidden, Typography } from "@mui/material";
import Button from "components/Button";
import Image from "next/image";
import theme from "theme";

const SingleBox = ({
  subject,
  text,
  image = process.env.HOMEPAGE_IMAGE,
  url,
}) => {
  return (
    <Box
      sx={{
        [theme.breakpoints.up("md")]: { flexBasis: "48%" },
        [theme.breakpoints.down("md")]: { flexBasis: "100%", padding: 4 },
        display: "flex",
      }}
    >
      <Box
        position="relative"
        display="flex"
        width="100%"
        padding="40px 60px"
        overflow="hidden"
        sx={{
          borderRadius: 2,
          [theme.breakpoints.down("md")]: {
            flexDirection: "column",
            padding: "100px 30px 40px",
          },
        }}
        justifyContent="flex-end"
      >
        <Box sx={{ zIndex: 1 }}>
          <Image
            unoptimized
            alt={`images discount ${text}`}
            layout="fill"
            objectFit="cover"
            src={image}
          />
        </Box>
        <Box
          bgcolor="white"
          borderRadius={1}
          sx={{
            zIndex: 2,
            [theme.breakpoints.up("md")]: { minWidth: "60%" },
            [theme.breakpoints.down("md")]: {
              flexBasis: "100%",
              borderRadius: 1,
            },
          }}
          p={4}
        >
          <Typography color="secondary" variant="h2">
            {subject}
          </Typography>
          <Typography
            style={{ color: "black" }}
            sx={{
              [theme.breakpoints.up("md")]: { paddingBottom: "20px" },
              [theme.breakpoints.down("md")]: {
                paddingBottom: "0",
                fontSize: "0.80rem",
              },
            }}
          >
            {text}
          </Typography>
          <Hidden mdDown>
            {url && (
              <a href={url} target="_blank" rel="noreferrer">
                <Button
                  color="secondary"
                  style={{ width: "75%", height: "40px" }}
                >
                  Sprawdź
                </Button>
              </a>
            )}
          </Hidden>
        </Box>
        <Hidden mdUp>
          {url && (
            <Box sx={{ zIndex: 1 }}>
              <a href={url} target="_blank" rel="noreferrer">
                <Button
                  color="secondary"
                  style={{ width: "100%", height: "40px", marginTop: "4px" }}
                >
                  Sprawdź
                </Button>
              </a>
            </Box>
          )}
        </Hidden>
      </Box>
    </Box>
  );
};

const Discount = ({ banners = [] }) => {
  return (
    <Box
      my={10}
      paddingY={2}
      display="flex"
      flexWrap="wrap"
      justifyContent="space-between"
    >
      {banners?.length > 0 &&
        banners?.map((el) => (
          <SingleBox
            key={el?.id}
            subject={el.head}
            text={el.body}
            url={el?.url}
            image={`${process.env.BASE_URL_SHORT}${el?.path}/${el?.file}`}
          />
        ))}
    </Box>
  );
};

export default Discount;

import { Box, Hidden, Typography } from "@mui/material";
import Button from "components/Button";
import theme from "theme";
import { ArrowForwardIos } from "@mui/icons-material";
import { LogoSVG } from "assets/svg";
import ImageKredyty from "../../../public/img/kretyty2.jpg";
import Image from "next/image";

const SingleBox = ({ subject, text, url }) => {
  return (
    <Box sx={{ flexBasis: "49%", display: "flex" }}>
      <Box
        position="relative"
        display="flex"
        width="100%"
        style={{
          padding: "40px 60px",
        }}
        sx={{
          [theme.breakpoints.down("md")]: {
            borderRadius: 0,
            marginBottom: "100px",
          },
        }}
      >
        <Box sx={{ zIndex: -2 }} className="nextImage">
          <Image
            unoptimized
            alt="images background"
            layout="fill"
            objectFit="cover"
            quality={25}
            src={ImageKredyty}
          />
        </Box>
        <Box
          bgcolor="white"
          borderRadius={1}
          border={1}
          borderColor={theme.palette.grey[300]}
          flexBasis="65%"
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          p={4}
          sx={{
            transform: "translateY(-25%)",
            [theme.breakpoints.down("md")]: {
              borderRadius: 2,
              flexBasis: "100%",
              transform: "translateY(25%)",
            },
          }}
        >
          <Typography variant="h3" lineHeight="1em" fontWeight={800} pb={4}>
            {subject}
          </Typography>
          <Typography style={{ fontSize: "0.95rem", color: "black" }}>
            {text}
          </Typography>

          <Hidden mdDown>
            <Box pt={2}>
              <a href={url} target="_blank" rel="noreferrer">
                <Button
                  color="secondary"
                  style={{ width: "75%", height: "40px" }}
                >
                  Dowiedz się więcej{" "}
                  <ArrowForwardIos
                    sx={{ fontSize: "20px", ml: 0.5 }}
                    color="white"
                  />
                </Button>
              </a>
            </Box>
          </Hidden>
          <Hidden mdUp>
            <Box pt={2}>
              <a href={url} target="_blank" rel="noreferrer">
                <Button
                  color="secondary"
                  style={{ width: "100%", height: "40px" }}
                >
                  Dowiedz się więcej{" "}
                  <ArrowForwardIos
                    sx={{ fontSize: "20px", ml: 0.5 }}
                    color="white"
                  />
                </Button>
              </a>
            </Box>
          </Hidden>
        </Box>
      </Box>
    </Box>
  );
};

const RealestateFromExpander = () => {
  return (
    <Box px={4}>
      <Box
        mt={20}
        maxWidth="1100px"
        marginX="auto"
        textAlign="center"
        sx={{
          [theme.breakpoints.down("md")]: {
            mt: 8,
          },
        }}
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          my={4}
          sx={{
            [theme.breakpoints.down("md")]: {
              flexDirection: "column",
            },
          }}
        >
          <Typography variant="h2">Nieruchomości od</Typography>
          <LogoSVG />
        </Box>
        <Typography style={{ fontSize: "1.25rem", color: "black" }}>
          Expander to pierwsza firma pośrednictwa finansowego na polskim rynku.
          Od 2000 roku dbamy o portfele Polaków oferując usługi z zakresu
          kredytów hipotecznych i gotówkowych, finansowania firm, ubezpieczeń
          oraz nieruchomości.
        </Typography>
        <Box py={4} marginBottom={16}>
          <Typography style={{ fontSize: "1.25rem", color: "black" }}>
            Nasz misją jest dostarczenie Klientom najlepiej dopasowanych
            produktów finansowych. Pomagamy spełniać marzenia i kreować
            rzeczywistość. To dla nas przywilej, ale również odpowiedzialność.
            Dlatego każdego dnia staramy się wzbogacić naszą ofertę, skupiać na
            indywidualnych potrzebach i upraszczać finanse.
          </Typography>
        </Box>
      </Box>
      <Box
        my={10}
        paddingY={2}
        display="flex"
        flexWrap="wrap"
        justifyContent="space-between"
        sx={{
          [theme.breakpoints.down("md")]: {
            flexDirection: "column",
            mt: 4,
          },
        }}
      >
        <SingleBox
          subject="Lokalizacja to znacznie więcej niż odległość od centrum"
          text="Szukamy miejsc, które najlepiej spełnią Twoje oczekiwania. Sprawdzamy czas dojazdu do wybranych przez Ciebie punktów oraz oceniamy otoczenie budynku."
          url="https://www.expander.pl/jak-kupic-mieszkanie-11-krokow/?utm_source=www_nieruchomosci&utm_medium=link&utm_campaign=baner"
        />

        <SingleBox
          subject="Wygodne finansowanie kredytem hipotecznym"
          text="Skorzystaj z całkowicie bezpłatnej pomocy eksperta finansowego w doborze optymalnego kredytu na mieszkanie lub juz teraz porównaj oferty kredytów nawet z 12 banków jednocześnie"
          url="https://www.expander.pl/kredyty/hipoteczne/?utm_source=www_nieruchomosci&utm_medium=link&utm_campaign=baner"
        />
      </Box>
    </Box>
  );
};

export default RealestateFromExpander;

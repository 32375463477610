import theme from "theme";

const { Box, Typography } = require("@mui/material");
import { KeySVG } from "assets/svg";

import Image from "next/image";
import postacIcon from "assets/icons/postac1.png";
import zeroZlIcon from "assets/icons/0zl.png";
import ochronaCheckIcon from "assets/icons/ochronacheck.png";
import kluczIcon from "assets/icons/klucz.png";

const SingleBox = ({ subject, text, icon }) => {
  return (
    <Box
      display="flex"
      mb={6}
      sx={{
        flexBasis: "50%",

        [theme.breakpoints.down("md")]: {
          border: `1px solid ${theme.palette.grey[200]}`,
          px: 2,
        },
      }}
    >
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{
          flexBasis: "30%",
          [theme.breakpoints.down("md")]: {
            flexBasis: "40%",
          },
        }}
      >
        {icon ? icon : <KeySVG size={92} />}
      </Box>
      <Box mx={4} display="flex" flexDirection="column">
        <Box my={4} display="flex">
          <Box sx={{ flexBasis: "75%" }}>
            <Typography
              variant="h3"
              color="primary"
              fontWeight={800}
              lineHeight="1em"
              sx={{ fontSize: "1.25rem !important" }}
            >
              {subject}
            </Typography>
          </Box>
        </Box>
        <Typography
          color="primary"
          sx={{
            [theme.breakpoints.down("md")]: {
              paddingBottom: "16px",
            },
          }}
        >
          {text}
        </Typography>
      </Box>
    </Box>
  );
};

const HowWeWork = () => {
  return (
    <Box>
      <Box
        py={8}
        px={10}
        sx={{
          display: "flex",
          flexWrap: "wrap",
          [theme.breakpoints.down("md")]: {
            flexDirection: "column",
            padding: "6px",
          },
        }}
      >
        <SingleBox
          subject="Pełen dostęp do informacji dzięki bezpłatnej rezerwacji"
          text="Nie musisz czekać na kontakt od dewelopera, aby poznać cenę
            mieszkania. Wystarczy, że się zalogujesz!"
          icon={
            <Image
              unoptimized
              alt={`klucz icon`}
              src={kluczIcon}
              width={64}
              height={96}
            />
          }
        />

        <SingleBox
          subject="Bez prowizji na rynku pierwotnym i ukrytych opłat"
          text="Kupując z nami mieszkanie od dewelopera nie
          zapłacisz ani prowizji ani za pomoc eksperta."
          icon={
            <Image
              alt={`zero zł icon`}
              src={zeroZlIcon}
              width={96}
              height={120}
            />
          }
        />

        <SingleBox
          subject="Wszystkie ofety zweryfikowane przez naszych ekspertów"
          text="Informacje o mieszkaniach są rzetelne i kompletne.
          Unikasz więc nieprzyjemnych niespodzianek."
          icon={
            <Image
              alt={`ochrona icon`}
              src={ochronaCheckIcon}
              width={74.25}
              height={86.4}
            />
          }
        />

        <SingleBox
          subject="Pomoc eksperta przy załatwianiu formalności"
          text="Informacje o mieszkaniach są rzetelne i kompletne.
          Unikasz więc nieprzyjemnych niespodzianek."
          icon={
            <Image
              alt={`postac icon`}
              src={postacIcon}
              width={96}
              height={96}
            />
          }
        />
      </Box>
    </Box>
  );
};

export default HowWeWork;

import axios from "utils/axios";

const BannerServices = {
  getBanners: async (proxy = false) => {
    return await axios
      .get(`/banner-index`)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        // console.log(err?.response);
        return err;
      });
  },
};

export default BannerServices;
